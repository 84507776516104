import React, { useState } from "react";
import { Helmet } from 'react-helmet';

import HomeNavBar from "./HomeNavBar";
import HomeFooter from "./HomeFooter";
import HomeSignUpCta from "./HomeSignUpCta";

import carrot from '../photos/icons/carrot.png';
import squash from '../photos/icons/squash.png';

export default function SpacingWidget() {
  const [spacingVal, setSpacingVal] = useState(12);
  const [countVal, setCountVal] = useState(1);
  const [displayCount, setDisplayCount] = useState(1);
  const [pixelSpacing, setPixelSpacing] = useState(0);

  const convertSpacingToCounts = (spacing) => {
    let counts = 0;
    if(spacing !== 0){
      if (parseFloat(spacing) === 1.25){
        return 90;
      }
      else if (parseFloat(spacing) === 1.75){
        return 42;
      }
      else if (parseFloat(spacing) === 2.5){
        return 20;
      }
      else if (parseFloat(spacing) === 3.25){
        return 12;
      }
      else if (parseFloat(spacing) === 3.5){
        return 10;
      }
      else if (parseFloat(spacing) === 2.25){
        return 25;
      }
      counts = Math.floor((12/spacing)*(12/spacing));
      return counts;
    }
    return counts;
  };
  
  const convertSpacingToPx = () => {
    const soilWidthHeight = 24*16;
    const pxPerInch = soilWidthHeight/12;
    const heightWidth = 16;
    const spacing = ((spacingVal*pxPerInch - heightWidth)/2); //return inch spacing in terms of pixels
    setPixelSpacing(spacing);
  };

  const handleChangeDisplay = (e) => {
    e.preventDefault();
    setDisplayCount(countVal);
    convertSpacingToPx();
  };

  const handleSpacingChange = (spacing) => {
    if(spacing && spacing !== 0){
      setSpacingVal(spacing);
      setCountVal(convertSpacingToCounts(parseFloat(spacing)));
    }
  };

  const getMargin = (index) => {
    if(displayCount === 3){
      if(index === 2){
        return [pixelSpacing + 80, pixelSpacing + 80];
      }
      else if(index === 0 || index === 1){
        return [pixelSpacing - 20, pixelSpacing - 20];
      }
    }
    if(displayCount === 5 && index === 2){
      return [pixelSpacing + 80, pixelSpacing + 80];
    }
    if(displayCount === 7){
      if(index === 2 || index === 3 || index === 4){
        return [pixelSpacing - 10, pixelSpacing - 10];
      }
      else if(index === 0 || index === 5){
        return [pixelSpacing + 20, pixelSpacing];
      }
      else if(index === 1 || index === 6){
        return [pixelSpacing, pixelSpacing + 20];
      }
    }
    if(displayCount === 10){
      if(index === 3 || index === 4 || index === 5 || index === 6){
        return [pixelSpacing - 12, pixelSpacing - 12];
      }
      else if(index === 0 || index === 7){
        return [pixelSpacing + 10, pixelSpacing];
      }
      else if(index === 2 || index === 9){
        return [pixelSpacing, pixelSpacing + 10];
      }
    }
    return [pixelSpacing, pixelSpacing];
  };

  return (
    <section className='home-page' itemScope itemProp="mainEntity" itemType="https://schema.org/AboutPage">
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Square Foot Spacing Calculator | Visualize Your Plant Spacing With Our Easy-to-Use Calculator</title>
        <meta name="description" content="Visualize spacing and counts of your plants with a few clicks!  The easiest tool on the internet to quickly and easily evaluate plant spacing."></meta>
        <link rel="canonical" href="https://www.plannt.ca/spacing-calculator"/>
      </Helmet>
      <HomeNavBar/>
      <div className="page-header  shift-down" id="seed-header">
        <h2 className="header-word" id="calc-page" style={{paddingTop:"1em"}}>Square Foot Plant Spacing Calculator</h2>
      </div>
      <div className='home-row shd' id="calculator" style={{marginTop:"0"}}>
        <div className="soil-patch">
          {Array.from(Array(displayCount).keys()).map((count) => {
            const [leftMargin, rightMargin] = getMargin(count);
            return (
              <div 
                className="plant-dot" key={count} 
                style={{margin:`${pixelSpacing}px ${rightMargin}px ${pixelSpacing}px ${leftMargin}px`}}
              />
            );
          })}
        </div>
        <form className="calculator" onSubmit={(e) => handleChangeDisplay(e)}>
          <div className="form-section">
            <div className="form-row" id="veg-icon-row">
              <img src={carrot} alt="veg icon"/>
            </div>
            <div className="form-row" id="slider-row">
              <label htmlFor="spacing-slider" className="calc-label">
                <h4 className="beet">Drag to change spacing</h4>
              </label>
              <div className="first-dot-bg">
                <div className="plant-dot" id="first-dot"/>
                <input
                  type="range"
                  id="spacing-slider"
                  name="spacing-slider"
                  min="0.5"
                  max="12"
                  step="0.25"
                  value={spacingVal}
                  onChange={(e) => handleSpacingChange(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row">
              <label htmlFor="spacing" className="calc-label">
                <h3>Plant Spacing (inches)</h3>
              </label>
              <input
                className="settings-input"
                id="spacing"
                type="number"
                min="0.5"
                max="12"
                step="0.25"
                value={spacingVal}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => handleSpacingChange(e.target.value)}
                placeholder="Number"
              />
            </div>
            <div className="form-row">
              <label htmlFor="counts" className="calc-label">
                <h3>Plants per square foot</h3>
              </label>
              <input
                className="settings-input"
                id="counts"
                type="number"
                min="1"
                max="576"
                step="1"
                value={countVal}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setCountVal(e.target.value)}
                placeholder="Number"
                disabled
              />
            </div>
          </div>
          <div className="form-row" style={{justifyContent:"center", marginTop:"1em"}}>
            <input type='submit' id="minor-button" value="show me!" style={{maxWidth:"6em"}}/>
          </div>
        </form>
      </div>
      <div>
        <h3 className="home-text" itemScope itemProp="mainEntity" itemType="https://schema.org/WebApplication">
          <div className="home-text-row" id="center-text">
            Sometimes, it can be tricky to visualize what a certain plant spacing will look like in your square foot garden. <span className="plannt">plannt.ca</span>'s free square foot plant spacing calculator makes it easy!
            <br/><br/>You may notice (if you do the math yourself) that many plant spacing values don't translate well to plants-per-square-foot values, especially if you're only using a single square foot.  
            We've compensated for this by recommending the closest plants-per-square-foot value that will give you an easy-to-follow spacing pattern. 
            <br/><br/>No need to worry about overcrowding- if you follow the layouts shown above, your plants will be spaced according to the provided plant spacing value at the very minimum (they may even get extra space!).
          </div>
          <div className="home-row" style={{width:"100%"}}>
            <img src={squash} alt="vegetable icon" className="veg-icons"/>
          </div>
          <div className="home-text-row" id="center-text">
            <span className="plannt">plannt.ca</span> is an online square foot garden planning tool for gardeners who <b>grow food from seed</b>.  Our intelligent mapping & scheduling software helps you <b>maximize your garden's productivity</b>, even in a small space.
            <br/><br/>Want to plan more than a single square foot of your garden?  Give our <b>free 2 week trial</b> a try. 
          </div>
        </h3>
      </div>
      <HomeSignUpCta/>
      <HomeFooter/>
    </section>
  );
}