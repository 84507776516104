import React from "react";

import instagram from '../../components/photos/icons/socials/instagram.png';
import twitter from '../../components/photos/icons/socials/twitter.png';
import youtube from '../../components/photos/icons/socials/youtube.png';
import blog from '../../components/photos/icons/socials/medium.png';

export default function Socials() {
  return (
    <div>
      <a itemProp="sameAs" href="https://www.instagram.com/plannt_ca/" target="_blank" rel="noreferrer noopener"><img src={instagram} alt="instagram icon" className="socials"></img></a>
      <a itemProp="sameAs" href="https://twitter.com/plannt_ca" target="_blank" rel="noreferrer noopener"><img src={twitter} alt="twitter icon" className="socials"></img></a>
      <a itemProp="sameAs" href="https://plannt.medium.com" target="_blank" rel="noreferrer noopener"><img src={blog} alt="medium icon" className="socials"></img></a>
      <a itemProp="sameAs" href="https://www.youtube.com/channel/UCgBHF516mTPftncMoKrsUOw" target="_blank" rel="noreferrer noopener"><img src={youtube} alt="youtube icon" className="socials"></img></a>
    </div>
  );
};