import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';

import Logo from "../landing/Logo";

export default function Agreement() {
  const AgreementContent = lazy(() => import('./AgreementContent'));

  return (
    <section className="policy-cont">
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>
      <div className="home-page" id="simple">
        <Logo/>
        <div style={{width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
          <form className="acct-form" style={{backgroundColor:"#fbf9f7", maxWidth:"60em", width: '90%'}}>
            <div className="form-title agree-form">
              <h1>Member Agreement</h1>
            </div>
            <div className="form-section" id="subscription">
              <Suspense fallback={<div><h3>Loading...</h3></div>}>
                <AgreementContent />
              </Suspense>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}