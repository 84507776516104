import { NavLink } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player/lazy';

import ROUTES from '../../App/routes';

// import parsleyStart from '../../components/vid/parsleyStart.webp';
import parsley from '../../components/vid/vlcsnap-parsley.webp';
import parsleyVid from '../../components/vid/parsley720.mp4';

import HomeNavBar from './HomeNavBar';
import HomeFooter from './HomeFooter';
import HomeSignUpCta from './HomeSignUpCta';

export default function Home() {
  const DemoPanel = lazy(() => import('./DemoPanel'));
  const CardPanel = lazy(() => import('./CardPanel'));

  const introVidLink = "https://www.youtube.com/embed/l376lypKLY4?si=AvLF0DBl1OAJUjy0";
  const introVidDesc = "Get Excited About Food Garden Planning | plannt.ca Demo Video 2024";
  const parsleyStart =  process.env.PUBLIC_URL+'/parsleyStart.webp';

  return (
    <section className='home-page'>
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Plannt.ca | The Ultimate Garden Planning Tool for Square Foot Gardeners</title>
        <meta name="description" content="Let's grow food from seed! Use Plannt to build an interactive map of your garden, generate your personalized schedule, and keep track of your tasks all growing season long."></meta>
        <link rel="canonical" href="https://www.plannt.ca/"/>
      </Helmet>
      <HomeNavBar/>
      <div className='home-info-panel' itemScope itemProp="mainEntity" itemType="https://schema.org/WebApplication">
        <div className="layer-cont">
          <div className='video-overlay'>
            <h1 className="big-motto text-bg" style={{maxWidth:"16.6em", paddingLeft:"0.25em", borderRadius: "0.5em"}}><div className='guess'>Plant</div> with an emphasis on the <div className='guess italic'>plan.</div></h1>
            <div className="home-blurb">
              <h2 className='text-bg' style={{width:"11.25em", paddingBottom:"0.5em", paddingTop:"0", borderRadius: "0.75em 0.75em 0em 0em"}}>You're the garden planner.</h2>
              <h2 className='text-bg'><span className="plannt lighter" itemProp="name">plannt.ca</span> is an <span itemProp="description">online garden planning tool that makes it simple to grow food from seed</span>.</h2>
              <h2 className='text-bg' style={{width:"8.75em", paddingTop:"0.5em", borderRadius: "0em 0em 0.75em 0.75em"}}>Let's grow together.</h2>
            </div>
            <span>
              <div className="home-form-row" style={{marginTop:"1.5em"}}>
                <div className="home-form-col">
                  <NavLink to={ROUTES.registerRoute()} className={(navData)=>navData.isActive ? "active" : "inactive"}>
                    <input id="free-trial" type="submit" value="start your free trial"/>
                  </NavLink>
                  <p className="trial-text">Setup only takes a minute!</p>
                </div>
              </div>
            </span>
          </div>
          <video autoPlay muted loop id="fullscreen-video" poster={parsleyStart} controlsList="nodownload" preload="auto" playsInline>
            <source src={parsleyVid} type="video/mp4" fetchpriority="high"/>
            <img src={parsley} width="1920" height="1080" alt="Parsley plant growing in the ground"/>
          </video>
        </div>
        <Suspense fallback={<div className='home-row' style={{minHeight:"2100px"}}><h3>Loading...</h3></div>}>
          <DemoPanel/>
        </Suspense>
        <div itemProp="video" className='home-col' id="band">
          <ReactPlayer
            width="640px"
            height="360px"
            url={introVidLink}
            title={introVidDesc}
            controls={true}
            light={true}
            id="youtube"
          />
        </div>
        <Suspense fallback={<div className='home-row' style={{minHeight:"506px"}}><h3>Loading...</h3></div>}>
          <CardPanel/>
        </Suspense>
        <HomeSignUpCta/>
      </div>
      <HomeFooter/>
    </section>
  );
}