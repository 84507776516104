import React from "react";
import { Helmet } from 'react-helmet';

import carrot from '../../components/photos/icons/carrot.png';
import cabbage from '../../components/photos/icons/cabbage.png';
import cauliflower from '../../components/photos/icons/cauliflower.png';
import tomato from '../../components/photos/icons/tomato.png';
import squash from '../../components/photos/icons/squash.png';

import corianderStart from '../../components/vid/corianderStart.webp';
import coriander from '../../components/vid/vlcsnap-coriander.webp';
import corianderVid from '../../components/vid/coriander720.mp4';

import rawFaqInfo from "./rawFaqInfo";
import HomeNavBar from "./HomeNavBar";
import HomeFooter from "./HomeFooter";

export default function Faqs() {
  const picList = [carrot, cabbage, cauliflower, tomato, squash];

  const getBottomPic = (index) => {
    const mult = Math.floor(index/picList.length);
    const accessInd = index - (picList.length*mult);
    return picList[accessInd];
  };

  return (
    <section className='home-page' itemScope itemProp="mainEntity" itemType="https://schema.org/FAQPage">
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Frequently Asked Questions | Plannt Online Garden Planning Tool</title>
        <meta name="description" content="Discover answers to frequently asked questions about plannt.ca, the ultimate garden planning tool. Learn how plannt.ca benefits both beginner and experienced gardeners, its availability in different regions, mobile compatibility, personalized recommendations, square foot gardening advantages, organization features, unique functionalities, referral program details, data security, and how to get further assistance. Start your 2-week free trial and unlock the full potential of your food garden with plannt.ca today!"></meta>
        <link rel="canonical" href="https://www.plannt.ca/faqs"/>
      </Helmet>
      <HomeNavBar/>
      <div className='home-info-panel'>
        <div className="layer-cont">
          <div className='video-overlay'>
            <h1 className="big-motto text-bg" id="faq-title" style={{paddingLeft:"0.25em", borderRadius: "0.5em"}} itemProp="name">Frequently Asked <div className='guess'>Questions</div></h1>
            <div className="home-blurb" itemProp="description">
              <h2 className='text-bg' style={{paddingBottom:"0.5em", paddingTop:"0", borderRadius: "0.75em 0.75em 0.75em 0em"}}>You may be wondering who we are and what <span className="plannt lighter">plannt.ca</span> is all about.</h2>
              <h2 className='text-bg' style={{maxWidth:"22.5em", paddingBottom:"0.5em", paddingTop:"0", borderRadius: "0em 0.75em 0.75em 0em"}}>Thankfully, we've got most of the answers right here.</h2>
              <h2 className='text-bg' style={{width:"9.75em", paddingBottom:"0.5em", paddingTop:"0", borderRadius: "0em 0em 0.75em 0.75em"}}>Let's talk about plants!</h2>
            </div>
          </div>
          <video autoPlay muted loop id="fullscreen-video" poster={corianderStart} preload="auto" controlsList="nodownload" playsInline>
            <source src={corianderVid} type="video/mp4"/>
            <img src={coriander} width="1920" height="1080" alt="Coriander plant growing in the ground" />
          </video>
        </div>
        <div className="posts-container">
          {rawFaqInfo.map((item, index) => {
            return (
              <React.Fragment key={item.I}>
                <div className="faq-post">
                  <details open itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                    <summary>
                      <h3 itemProp="name" className="post-title">
                        {item.Q}
                      </h3>
                    </summary>
                    <div itemProp="acceptedAnswer" itemScope itemType="http://schema.org/Answer">
                      <h4 className="post-body" itemProp="text">
                        {item.A}
                      </h4>
                    </div>
                  </details>
                </div> 
                <img src={getBottomPic(index)} alt="vegetable icon" className="veg-icons" width="64" height="64"/>
              </React.Fragment>
            );
          })}
        </div>
        <h4 id="cta-blog">Want to learn even more?  Check out <a href="https://plannt.medium.com" target="_blank" rel="noopener noreferrer">our blog</a>!</h4>
      </div>
      <HomeFooter/>
    </section>
  );
}