import React from "react";
import { Helmet } from 'react-helmet';

import Socials from './Socials';
import Logo from './Logo';

export default function ContactUs() {
  return (
    <section className='full-page' id="full-view" itemScope itemProp="mainEntity" itemType="https://schema.org/ContactPage">
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Contact Us | Plannt Online Garden Planning Tool</title>
        <meta name="description" content="If you're interested to contact plannt.ca, check out this page for our email, mailing address, and links to our social media profiles."></meta>
        <link rel="canonical" href="https://www.plannt.ca/contact"/>
      </Helmet>
      <div className="home-page" id="simple" itemScope itemType="https://schema.org/Organization">
        <Logo/>
        <div className='login'>
          <div className="form-title">
            <h1>Contact Us</h1>
          </div>
          <div className="contact-col">
            <h2 className="contact-title">Email</h2>
            <h3 className="contact-info">
              <a href="mailto:hello@plannt.ca" itemProp="email">
                hello@plannt.ca
              </a>
            </h3>
            <h2 className="contact-title">Mail</h2>
            <h3 className="contact-info" itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
              Plannt Solutions<br/>
              <span itemProp="streetAddress">PO Box 37046</span><br/>
              <span itemProp="addressLocality">Kitchener</span>,
              <span itemProp="addressRegion">ON</span><br/>
              <span itemProp="postalCode">N2B 3V5</span>
            </h3>
            <h2>Socials</h2>
            <Socials/>
          </div>
        </div>
      </div>
    </section>
  );
}