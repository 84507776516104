import './App.css';
import React from "react";
import ReactGA from "react-ga4";
import { getCookieConsentValue } from "react-cookie-consent";
import {
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import ROUTES from "./routes";

import Home from "../components/landing/Home";
import About from '../components/landing/About';
import Faqs from '../components/landing/Faqs';
import Pricing from '../components/landing/Pricing';
import ContactUs from '../components/landing/ContactUs';
import MissingPage from '../components/landing/MissingPage';
import PrivacyPolicy from '../components/legal/PrivacyPolicy';
import TermsOfUse from '../components/legal/TermsOfUse';
import Agreement from '../components/legal/Agreement';
import SpacingWidget from '../components/landing/SpacingWidget';

export default function App() {
  if(process.env.NODE_ENV !== "development"){
    const isConsent = getCookieConsentValue();
    if(isConsent !== "true") {
      ReactGA.gtag("consent", "default", {
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied",
        analytics_storage: "denied",
      });
    }
    ReactGA.initialize("G-2768HX3GDP");
  }

  return (
    <Routes>
      <Route path={ROUTES.homeRoute()} element={<Home/>} />
      <Route path={ROUTES.aboutRoute()} element={<About/>} />
      <Route path={ROUTES.faqRoute()} element={<Faqs/>} />
      <Route path={ROUTES.priceRoute()} element={<Pricing/>} />
      <Route path={ROUTES.contactUsRoute()} element={<ContactUs/>} />
      <Route path={ROUTES.termsOfUseRoute()} element={<TermsOfUse/>} />
      <Route path={ROUTES.privacyPolicyRoute()} element={<PrivacyPolicy/>} />
      <Route path={ROUTES.missingRoute()} element={<MissingPage/>} />
      <Route path={ROUTES.agreementPublicRoute()} element={<Agreement/>}/>
      <Route path={ROUTES.spacingWidgetRoute()} element={<SpacingWidget/>}/>
      <Route path="*" element={<Navigate to={ROUTES.missingRoute()} />} />
    </Routes>
  );
}
