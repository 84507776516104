import React from "react";
import { Helmet } from 'react-helmet';

import tomato from '../../components/photos/icons/tomato.png';
import carrot from '../../components/photos/icons/carrot.png';
import squash from '../../components/photos/icons/squash.png';
import basilStart from '../../components/vid/basilStart.webp';
import basil from '../../components/vid/vlcsnap-basil.webp';
import basilVid from '../../components/vid/basil720.mp4';

import HomeNavBar from "./HomeNavBar";
import HomeFooter from "./HomeFooter";
import HomeSignUpCta from "./HomeSignUpCta";

export default function About() {
  return (
    <section className='home-page' itemScope itemProp="mainEntity" itemType="https://schema.org/AboutPage">
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>About Plannt.ca | Why We Made the Ultimate Food Garden Planner | About Plannt</title>
        <meta name="description" content="Plan, optimize, and grow your own fresh and delicious food with plannt.ca. Our intuitive platform simplifies square foot gardening, offering an interactive garden map, personalized schedule, and seed library. Maximize your harvests, manage tasks, log harvests, and review analytics for your thriving garden. Join us today and cultivate a greener, healthier future— one garden at a time."></meta>
        <link rel="canonical" href="https://www.plannt.ca/about"/>
      </Helmet>
      <HomeNavBar/>
      <div className='home-info-panel'>
        <div className="layer-cont">
          <div className='video-overlay'>
            <h1 className="big-motto text-bg" style={{width:"4.25em", paddingLeft:"0.25em", borderRadius:"0.5em"}} itemProp="name">About <div className='guess'>Us</div></h1>
            <div className="home-blurb" itemProp="description">
              <h2 className='text-bg' style={{paddingBottom:"0.5em", paddingTop:"0", borderRadius: "0.75em 0.75em 0.75em 0em"}}>
                What, why, and where in the world is <span className="plannt lighter">plannt.ca</span>?
              </h2>
              <h2 className='text-bg' style={{width:"12.75em", paddingBottom:"0.5em", paddingTop:"0", borderRadius: "0em 0em 0.75em 0.75em"}}>
                We're so glad that you asked.
              </h2>
            </div>
          </div>
          <video autoPlay muted loop id="fullscreen-video" poster={basilStart} controlsList="nodownload" preload="auto" playsInline>
            <source src={basilVid} type="video/mp4"/>
            <img src={basil} width="1920" height="1080" alt="Basil plant growing in the ground" />
          </video>
        </div>
        <div>
          <h3 className="home-text" itemScope itemProp="mainEntity" itemType="https://schema.org/WebApplication">
            <div className="home-text-row">
              Welcome to <span itemProp="name"><span className="plannt">plannt.ca</span></span>: <span itemProp="description">Your Ultimate Online Food Garden Planning Tool</span>!
              <br/><br/>At <span className="plannt">plannt.ca</span>, we believe that everyone deserves the joy of growing their own fresh and delicious food, no matter the size of their growing space. 
              We are passionate about providing the planning tools that gardeners need to <b>maximize their harvests</b> and create <b>thriving, high-variety food gardens</b> even in a small space.
              <br/><br/>Why "plannt", you may ask?  Well, you can think of our name this way: <em>"Plant with an emphasis on the Plan"</em>.
              <br/><br/>And that's what we're all about.
              <br/><br/>Whether you're a seasoned green thumb or just starting your gardening journey, <span className="plannt">plannt.ca</span> is here to simplify and enhance your experience every step of the way.
              Our online garden planning platform is designed specifically for <b>square foot gardening</b>. 
              <br/><br/>Why did we choose this method?
              <br/><br/>We've tried many different methods over the years and found that working in units of square feet is both an <b>efficient way to use your space</b> and 
              makes it easier IRL to track where you've physically placed your crops.  We want to offer our gardeners the most <b>straightforward planning experience</b> and remove complicating 
              factors that make it more difficult to gather insights from each growing season.
            </div>
            <div className="home-row">
              <img src={carrot} alt="veg icon" width="64" height="64"/>
            </div>
            <div className="home-text-row">
              Founded in 2022 in <span itemProp="locationCreated">Kitchener, Ontario, Canada</span> <span className="plannt">plannt.ca</span> was born out of the vision of a manufacturing engineer (and avid gardener), <span itemProp="creator" itemScope itemType="https://schema.org/Person">
                <span itemProp="name">Alex Lizewski</span>
              </span>. 
              <br/><br/>Alex's passion for gardening began shortly after moving from Calgary, Alberta to Kitchener, Ontario in 2017.  
              After discovering the incredible variety of food that was <b>grown locally</b>,
              she was inspired to try it out for herself.  She immediately got to work building a food garden in her suburban back yard.  
              Drawing on her experience in setting up factory assembly lines, she planned everything out with DIY spreadsheets and hand-drawn maps.
              <br/><br/>During her first growing season, she made a lot of mistakes, learned a lot, and <b>revised her plan several times</b>.  
              Although her garden went through many ups and downs, her efforts were finally rewarded when she was able to taste her first home-grown tomato.  
              <br/><br/>Despite the struggle to get to that point, <b>she was hooked</b>.
              <br/><br/>Inspired by the flavors and sense of accomplishment that came with her own <b>homegrown produce</b>, Alex made it her mission to enable others to experience the same.
              However, with her limited space and interest in <b>experimenting with many different varieties</b>, she found that spreadsheets and hand-drawn maps required far too much maintenance.
              <br/><br/>A food garden already requires enough attention without also needing to maintain its plan!
              <br/><br/>In order to <b>make food gardening a sustainable part of her lifestyle</b>, Alex recognized the need for a comprehensive tool that would empower her to easily plan and track her food garden.
              Driven by the vision of making <b>fresh local produce</b> accessible to everyone, she developed an innovative solution that would 
              make it possible for <b>anyone at any skill level</b> to plan and manage a food garden, particularly in limited spaces. 
            </div>
            <div className="home-row">
              <img src={squash} alt="veg icon" width="64" height="64"/>
            </div>
            <div className="home-text-row">
              At <span className="plannt">plannt.ca</span>, we understand the unique challenges faced by food gardeners, urban farmers, and homesteaders. 
              <br/><br/>Limited space, confusing instructions, and shifting schedules can make the planning process overwhelming.
              The temptation to give up and "wing it" is high, but the cost of mistakes is higher.  
              One miscalculation could mean that you've lost your chance to grow a certain crop for the entire season.
              <br/><br/>We don't want the planning process to be what keeps you from getting your own homegrown produce.
              That's why we've created an intuitive and user-friendly platform that empowers you to manage your dream garden effortlessly.  
              If you're able to <b>read the instructions on a seed packet</b>, you're ready to get growing! 
              <br/><br/>
              <span itemProp="abstract">
                Our interactive garden planner allows you to arrange a wide variety of crops onto a virtual grid, ensuring optimal use of every square foot. 
                You can experiment with different layouts, adjust plant spacing, and visualize your garden before putting a single seed into the ground. 
                With <span className="plannt">plannt.ca</span>, planning becomes a breeze, saving you time, money, and potential frustration.
              </span>
              <br/><br/>We understand that <b>gardening is a dynamic process</b> that evolves with the seasons. 
              That's why our interactive seasonal planner is not only designed to set up your plan, but to <b>keep up with changes to your plan</b>.
              <ul className="about-content">
                <li>
                  Our <b>analytics dashboard</b> allows you to track the success of your growing season in real time, helping you leverage your growing experience for the next season. 
                </li>
                <li>
                  Our <b>seed tracking library</b> empowers you to capture specifications, make informed purchase decisions, and achieve optimal results.
                </li>
                <li>
                  Once you define what you want to grow, <span className="plannt">plannt.ca</span> generates your <b>personalized task schedule</b> tailored to your specific location and garden preferences. 
                </li>
                <li>
                  You'll receive weekly <b>reminders</b> for tasks such as sowing seeds, transplanting seedlings, and harvesting your crops, and many more!  
                  Never miss a deadline again, and better yet, keep an eye on crops that aren't performing the way they claim to! 
                </li>
              </ul>
              As you can see, <span className="plannt">plannt.ca</span> is more than just a planning tool. 
              It's a <b>comprehensive resource hub</b> that equips you with the knowledge and guidance you need to cultivate a thriving food garden. 
            </div>
            <div className="home-row">
              <img src={tomato} alt="veg icon" width="64" height="64"/>
            </div>
            <div className="home-text-row">
              At <span className="plannt">plannt.ca</span>, we believe in the power of community. 
              Share the love by referring your friends.  
              Together, we can feed our communities, nurture our environment, and contribute to a greener, healthier planet.
              <br/><br/>As a company, <span className="plannt">plannt.ca</span> is committed to sustainability and environmental stewardship. 
              We strive to reduce our ecological footprint and promote eco-friendly gardening practices. 
              We continually explore innovative solutions and partnerships to further our mission of making <b>sustainable food gardening</b> accessible to all.
              <br/><br/>We're obsessed with the <b>#solarpunk</b> movement, and have a weekly post series on our <a href="https://www.instagram.com/plannt_ca/" target="_blank" rel="noreferrer noopener">instagram</a> where we explore ideas of how society might look in a more sustainable future.
              <br/><br/>Join us at <span className="plannt">plannt.ca</span> and allow us to support you on the journey of growing your own fresh and nutritious food. 
              Take it from us: once you experience the joy of harvesting your own homegrown food, you will be inspired to continue learning and growing. 
              <br/><br/>Together, let's sow the seeds of a greener future, one garden at a time.
            </div>
          </h3>
        </div>
        <HomeSignUpCta/>
      </div>
      <HomeFooter/>
    </section>
  );
}