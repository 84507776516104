import { useLocation } from 'react-router-dom';
import React, { useState, useRef, useEffect } from "react";

import ROUTES from '../../App/routes';
import Logo from './Logo';
import hamburger from '../../components/img/hamburger.png';

export default function HomeNavBar() {
  const location = useLocation();
  const page = location.pathname.split('/')[1];

  const [isExpanded, setIsExpanded] = useState(true);
  const [isDropDownExpanded, setIsDropDownExpanded] = useState(false);

  const buttonRef = useRef();
  const menuRef = useRef();

  const lowerMenuButtonRef = useRef();
  const lowerMenuRef = useRef();

  const handleMouseLeave = (e) => {
    setTimeout(() => setIsExpanded(!isExpanded), 10);
  };

  const handleClickOutsideMenu = (e) => {
    if(!menuRef.current.contains(e.target) && !buttonRef.current.contains(e.target)){
      setIsExpanded(true);
    }
  };

  const handleClickOutsideLowMenu = (e) => {
    if(!lowerMenuRef.current.contains(e.target) && !lowerMenuButtonRef.current.contains(e.target)){
      setIsDropDownExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMenu, true);
    document.addEventListener('click', handleClickOutsideLowMenu, true);
    return () => {
      document.removeEventListener('click', handleClickOutsideMenu, true);
      document.removeEventListener('click', handleClickOutsideLowMenu, true);
    };
  }, []);

  return (
    <div className="top-bar">
      <div className="top-container">
        <div className="left-top-container">
          <Logo/>
          <button ref={buttonRef} className="top-menu-burger" onClick={()=>{setIsExpanded(!isExpanded)}}>
            <img src={hamburger} alt="hamburger menu" width="33px" height="33px"/>
          </button>
          <ol itemScope itemType="https://schema.org/BreadcrumbList"
            ref={menuRef} 
            className={isExpanded ? "top-menu-panel expanded" : "top-menu-panel"} 
            onMouseLeave={!isExpanded ? ()=>{handleMouseLeave()}:undefined}
          >
            <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem"
              className={page==="about" ? "top-button-selected top-menu-button":"top-menu-button"} 
              onClick={()=>{setIsExpanded(!isExpanded)}}
            >
              <a href={ROUTES.aboutRoute()} itemProp="item">
                <span itemProp="name">About</span>
              </a>
              <meta itemProp="position" content="1" />
            </li>
            <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem"
              className={page==="faqs" ? "top-button-selected top-menu-button":"top-menu-button"} 
              onClick={()=>{setIsExpanded(!isExpanded)}}
            >
              <a href={ROUTES.faqRoute()} itemProp="item">
                <span itemProp="name">FAQs</span>
                <meta itemProp="position" content="2" />
              </a>
            </li>
            <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem"
              className={page==="pricing" ? "top-button-selected top-menu-button":"top-menu-button"} 
              onClick={()=>{setIsExpanded(!isExpanded)}}
            >
              <a href={ROUTES.priceRoute()} itemProp="item">
                <span itemProp="name">Pricing</span>
                <meta itemProp="position" content="3" />
              </a>
            </li>
            <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem"
              className="top-menu-button"
              id="mobile-hide"
              onClick={()=>{setIsDropDownExpanded(!isDropDownExpanded)}}
              ref={lowerMenuButtonRef}
            >
              <div itemProp="item">
                <span itemProp="name">Resources</span>
                <meta itemProp="position" content="4" />
              </div>
            </li>
            <ol 
              className={isDropDownExpanded ? "lower-menu-panel expanded" : "lower-menu-panel"} 
              ref={lowerMenuRef}
            >
              <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem"
                className="top-menu-button" onClick={()=>{setIsExpanded(!isExpanded)}}
              >
                <a href="https://plannt.medium.com" target="_blank" rel="noreferrer noopener" itemProp="item">
                  <span itemProp="name">Blog</span>
                  <meta itemProp="position" content="5" />
                </a>
              </li>
              <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem"
                className="top-menu-button" onClick={()=>{setIsExpanded(!isExpanded)}}
              >
                <a href={ROUTES.docsRoute()} target="_blank" rel="noreferrer noopener" itemProp="item">
                  <span itemProp="name">How To Guides</span>
                  <meta itemProp="position" content="6" />
                </a>
              </li>
              <li itemScope itemProp="itemListElement" itemType="https://schema.org/ListItem"
                onClick={()=>{setIsExpanded(!isExpanded)}}
                className={page==="spacing-calculator" ? "top-button-selected top-menu-button":"top-menu-button"} 
              >
                <a href={ROUTES.spacingWidgetRoute()} itemProp="item">
                  <span itemProp="name">Spacing Calculator</span>
                  <meta itemProp="position" content="7" />
                </a>
              </li>
            </ol>
          </ol>
        </div>
        <div className = "home-button-panel">
          <a href={ROUTES.loginRoute()}>
            <input type="submit" id="login-inactive" value="log in"/>
          </a>
          <a href={ROUTES.registerRoute()}>
            <input id="register-btn" type="submit" value="sign up"/>
          </a>
        </div>
      </div>
    </div>
  );
}