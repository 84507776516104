import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import Logo from "../landing/Logo";

export default function PrivacyPolicy() {
  const PrivacyPolicyContent = lazy(() => import('./PrivacyPolicyContent'));
  return (
    <section className="policy-cont">
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>
      <div className="home-page" id="simple">
        <Logo/>
        <div className="policy">
          <Suspense fallback={<div className="policy-header"><h2>Loading...</h2></div>}>
            <PrivacyPolicyContent/>
          </Suspense>
        </div>
      </div>
    </section>
  );
}