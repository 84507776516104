import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";

import ROUTES from "../../App/routes";

export default function CookieConsentBanner() {
  const handleAcceptCookie = () => {
    ReactGA.gtag("consent", "update", {
      analytics_storage: "granted",
    });
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");

    ReactGA.gtag("consent", "default", {
      ad_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
      analytics_storage: "denied",
    });
  };

  //look up whether visitor has already consented to cookies
  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if(isConsent !== "true") {
      handleDeclineCookie();
    }
  }, []);

  return (
    <CookieConsent
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
      enableDeclineButton
      flipButtons
      location="bottom"
      buttonText="I accept"
      declineButtonText="I decline"
      cookieName="CookieConsent"
      style={{justifyContent:"center", textAlign:"center", background: "#b49575cd", color:"#000", fontFamily:"Fredoka One", fontSize: "16px"}}
      buttonStyle={{color:"#000", backgroundColor: "#6BAE87", fontSize: "15px", fontFamily: 'Fredoka One', borderRadius: '0.9em', boxShadow:'0.2em 0.2em #4B3A26', marginLeft:"2em" }}
      declineButtonStyle={{color:"#000", backgroundColor: "#FF6347", fontSize: "15px", fontFamily: 'Fredoka One', borderRadius: '0.9em', boxShadow: '0.2em 0.2em #4B3A26', marginRight:"2em" }}
      expires={150}
    >
      This website uses cookies to analyze our traffic and enhance user experience.  For details, please refer to our <a href={ROUTES.privacyPolicyRoute()}>Privacy Policy</a>.
    </CookieConsent>
  );
};
