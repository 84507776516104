import ROUTES from '../../App/routes';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Logo from './Logo';

export default function MissingPage() {
  return (
    <section className='full-page' id="full-view">
      <Helmet>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>
      <div className='logo-panel'>
        <Logo/>
        <h2>404 Error</h2>
        <h3 className = "motto">Page Not Found!</h3>
        <br></br>
        <NavLink to={ROUTES.homeRoute()} className={(navData)=>navData.isActive ? "active" : "inactive"}>
          <input type="submit" value="RETURN TO HOME PAGE" />
        </NavLink>
      </div>
    </section>
  );
}