const ROUTES = {
    //SITEMAP
    homeRoute: () => "/",
    aboutRoute: () => "/about",
    faqRoute: () => "/faqs",
    priceRoute: () => "/pricing",
    contactUsRoute: () => "/contact",
    spacingWidgetRoute: () => "/spacing-calculator",
    //NOINDEX
    termsOfUseRoute: () => "/termsofuse",
    privacyPolicyRoute: () => "/privacypolicy",
    agreementPublicRoute: () => "/agreement",
    missingRoute: () => "/404",
    //EXTERNAL
    loginRoute: () => "https://app.plannt.ca/login",
    registerRoute: () => "https://app.plannt.ca/register",
    docsRoute: () => "https://docs.plannt.ca/"
};

export default ROUTES;