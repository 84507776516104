import React from "react";
import { Helmet } from 'react-helmet';
import ROUTES from '../../App/routes';

import cabbage from '../../components/photos/icons/cabbage.png';
import oreganoStart from '../../components/vid/oreganoStart.webp';
import oregano from '../../components/vid/vlcsnap-oregano.webp';
import oreganoVid from '../../components/vid/oregano720.mp4';

import HomeSignUpCta from "./HomeSignUpCta";
import HomeFooter from "./HomeFooter";
import HomeNavBar from "./HomeNavBar";

export default function Pricing() {
  return (
    <section className='home-page' itemScope itemProp="mainEntity" itemType="https://schema.org/WebApplication">
      <Helmet>
        <meta charset="UTF-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Pricing and Referral Program | Plannt Online Garden Planning Tool</title>
        <meta name="description" content="Discover the pricing structure and referral program offered by plannt.ca. Our 2-week FREE trial gives unlimited access to our powerful garden planning features, personalized recommendations, and extensive resources. Get up to 50% OFF by..."></meta>
        <link rel="canonical" href="https://www.plannt.ca/pricing"/>
      </Helmet>
      <HomeNavBar/>
      <div className='home-info-panel'>
        <div className="layer-cont">
          <div className='video-overlay'>
            <h1 className="big-motto text-bg" id="pricing-title" style={{paddingLeft:"0.25em", borderRadius:"0.5em"}} itemProp="name">Pricing & <div className='guess'>Special</div> Offers</h1>
            <div className="home-blurb" itemProp="description">
            <h2 className='text-bg' style={{paddingBottom:"0.5em", paddingTop:"0", borderRadius: "0.75em 0.75em 0.75em 0em"}}>
              What does <span className="plannt lighter" itemProp="name">plannt.ca</span> cost, and how are payments structured?
            </h2>
            <h2 className='text-bg' style={{width:"13.5em", paddingBottom:"0.5em", paddingTop:"0", borderRadius: "0em 0em 0.75em 0.75em"}}>
              (And how can I get a discount?)
            </h2>
            </div>
          </div>
          <video autoPlay muted loop id="fullscreen-video" poster={oreganoStart} preload="auto" controlsList="nodownload" playsInline>
            <source src={oreganoVid} type="video/mp4"/>
            <img src={oregano} width="1920" height="1080" alt="Oregano plant growing in the ground" />
          </video>
        </div>
        <h3 className="home-text">
          We believe in making our services accessible to all gardeners, which is why we offer a straightforward pricing model. 
          <br/><br/>For a competitive annual subscription fee, you gain full access to <span className="plannt">plannt.ca</span>'s powerful features, including our interactive garden planner, personalized recommendations, and a wealth of gardening resources.
          <br/><br/>We understand that committing to a new platform takes some deliberation, which is why we offer a <em>two-week free trial</em> without requiring payment information. 
          During this trial period, you can explore our platform, test its capabilities, and determine if <span className="plannt">plannt.ca</span> aligns with your gardening goals and needs. 
          We want you to have ample time to experience the value our tool brings to your gardening journey.
          <br/><br/>With an annual subscription, you gain unlimited access to our <span itemProp="description">garden planning platform</span> throughout the year, ensuring that you have the support and guidance you need from planning to harvest. 
          By investing in a <span className="plannt">plannt.ca</span> subscription, you unlock a wealth of knowledge, maximize your gardening potential, and elevate your garden to new heights of productivity and enjoyment.
        </h3>
        <h2 className="separation-word">Pricing</h2>
        <div className='home-col' id="pricing-row" itemProp="offers" itemScope itemType="http://schema.org/Offer">
          <table className="prices">
            <thead>
              <tr>
                <th>Trial</th>
                <th itemProp="description">Subscription</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><h2 style={{fontSize:"2.25em", paddingTop:"0.75em"}}>Free</h2>
                  <span className="lower">2 weeks</span><br/>
                  <span className="lower">full premium access</span>
                </td>
                <td>
                  <h2 style={{fontSize:"2.25em", paddingTop:"0.75em"}}>$<span itemProp="price">54</span> <span itemProp="priceCurrency">CAD</span></h2>
                  <span className="lower">+ applicable tax</span><br/>
                  <span className="lower">per year</span><br/><br/>
                </td>
              </tr>
            </tbody>
          </table>
          <a href={ROUTES.registerRoute()}>
            <input id="free-trial" type="submit" value="get started" />
          </a>
        </div>
        <h2 className="separation-word">Referral Program</h2>
        <h3 className="home-text">
          At <span className="plannt">plannt.ca</span>, we believe that we grow better together. That's why we've introduced our referral program, allowing you to earn discounts while spreading the love for the platform. 
          <br/><br/><em>Here's how it works:</em>
          <ol className="about-content">
            <li>
              Once you become a <span className="plannt">plannt.ca</span> subscriber, you'll gain access to a referral portal that allows you to share your unique referral link with your friends, family, or fellow gardening enthusiasts. 
            </li>
            <li>
              When someone signs up for <span className="plannt">plannt.ca</span> using your referral link and becomes a paid subscriber, they'll receive a discount on their subscription and you'll automatically receive a 10% discount off your next annual subscription renewal.  
            </li>
            <li>
              It doesn't stop there!  You can continue receiving discounts for every additional referred user for a maximum of 50% off your renewal cost.
            </li>
          </ol>
          We encourage you to share your love for <span className="plannt">plannt.ca</span> through your social networks, gardening groups, or even in conversations with fellow green thumbs. 
          Our goal is to allow more home gardeners to experience the difference a proper planning tool can make in their garden's performance.
          Together, we can empower more people to cultivate sustainable and thriving gardens.
        </h3>
        <div className="home-row">
          <img src={cabbage} alt="veg icon" width="64" height="64"/>
        </div>
        <h2 className="separation-word">Special Offers</h2>
        <h3 className="home-text">
          Follow us on <a href={ROUTES.contactUsRoute()}>social media</a> to catch our seasonal promotions and offers.
          <br/><br/>Whether you're a new gardener, a homesteader, or an urban farmer with a passion for growing your own food, <span className="plannt">plannt.ca</span> offers the tools and resources you need to succeed. 
          Our simple annual billing structure ensures that you have uninterrupted access to our platform, while our referral program allows you to earn discounts by introducing others to the benefits of <span className="plannt">plannt.ca</span>.
          <br/><br/>Join us today and unlock the full potential of your garden. Embrace the convenience, precision, and joy that comes with using <span className="plannt">plannt.ca</span>. 
          Start your free trial now and experience a worry-free gardening journey like never before. 
          <br/><br/>Together, let's grow and thrive, one garden at a time.
        </h3>
        <HomeSignUpCta/>
      </div>
      <HomeFooter/>
    </section>
  );
}