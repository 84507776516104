import ROUTES from "../../App/routes";

const faqList = [
    {
        I: 'F1',
        Q: <>What is <span className="plannt">plannt.ca</span> and how can it benefit me?</>,
        A: <>
            <span className="plannt">plannt.ca</span> is a powerful online garden planning tool designed to help square foot gardeners maximize their harvests and create thriving gardens, even in small spaces. 
            <br/><br/>We've got lots of features, but the highlights are:
            <ul>
                <li>
                    Our interactive garden map that tracks plant counts in each square foot, allowing for precise planning and efficient space utilization. 
                </li>
                <li>
                    Your own personal seed library allows you to gather detailed information on the specific seeds you care about, and empowers you to set your preferences.  
                </li>
                <li>
                    Your schedule for the season is automatically generated per crop based on the needs of each seed, combined with your own preferences.  
                </li>
                <li>
                    Weekly email task reminders give you a heads up on what you should be doing to care for your crops.
                </li>
            </ul>
            With our intuitive interface, personalized recommendations, and helpful resources, you can effortlessly plan, track, and optimize your garden's productivity. 
            By using <span className="plannt">plannt.ca</span>, you can save time, maximize your yields, and experience the joy of growing your own fresh and delicious food.
            </>
    },
    {
        I: 'F2',
        Q: <>Which regions is <span className="plannt">plannt.ca</span> available in?</>,
        A: <>
            Gardeners in the United States and all provinces & territories of Canada with the exception of Quebec can use <span className="plannt">plannt.ca</span>'s garden planning software. 
            <br/><br/>The platform is currently not available in Quebec due to certain regulatory challenges unique to the region. 
            We are constantly evaluating our options and may offer the product in Quebec in the future.
        </>
    },
    {
        I: 'F3',
        Q: <>Can I use <span className="plannt">plannt.ca</span> on my mobile device or tablet?</>,
        A: <>
            Of course!  The entire <span className="plannt">plannt.ca</span> site is mobile friendly and can be accessed from the browser of your smartphone or tablet. 
            No download required! 
            <br/><br/>For the best experience, we recommend doing the initial planning of your yard on a desktop computer or laptop.
        </>
    },
    {
        I: 'F4',
        Q: <>I'm new to gardening. Will <span className="plannt">plannt.ca</span> be suitable for me?</>,
        A: <>
            Absolutely! <span className="plannt">plannt.ca</span> is designed to cater to gardeners of all experience levels. 
            Whether you're a beginner or a seasoned green thumb, you will find guidance and support every step of the way. 
            <br/><br/>With a user-friendly interface that simplifies planning, <span className="plannt">plannt.ca</span> will help you navigate the world of gardening with confidence.
        </>
    },
    {
        I: 'F5',
        Q: <>Does <span className="plannt">plannt.ca</span> work for gardeners with limited space?</>,
        A: <>
            <span className="plannt">plannt.ca</span> specializes in square foot gardening, which is ideal for those with limited space. 
            Our interactive garden map allows you to design your dream garden layout in a grid formation. 
            <br/><br/>You can arrange (and rearrange!) your crops on the grid, ensuring optimal use of every square foot.
            This makes it effortless to manage many different varieties of crops at the same time.
        </>
    },
    {
        I: 'F6',
        Q: <>How can I learn how to use <span className="plannt">plannt.ca</span>?</>,
        A: <>
            To access easy-to-follow tutorial videos, visit our <a href={ROUTES.docsRoute()} target="_blank" rel="noreferrer noopener">Education site.</a>
        </>
    },
    {
        I: 'F7',
        Q: <>How does <span className="plannt">plannt.ca</span> provide personalized recommendations?</>,
        A: <>
            <span className="plannt">plannt.ca</span> takes into account your location and preferences to build your garden plan. 
            By entering your postal code or zip code, we find your frost dates and use this as the basis for your growing season schedule. 
            <br/><br/>Also, the specific seeds you use and enjoy are collected in your personal library, and are used to build the map of your garden.
            Rest assured!  Your plan is tailored to you so that you can make the most of your growing season.
        </>
    },
    {
        I: 'F8',
        Q: <>How can <span className="plannt">plannt.ca</span> help me stay on top of my gardening game?</>,
        A: <>
            <span className="plannt">plannt.ca</span> at its core is an interactive seasonal planner that keeps you on track with your gardening tasks. 
            You will receive weekly email reminders that summarize upcoming important tasks such as sowing seeds, transplanting seedlings, harvesting crops, and more. 
            <br/><br/>Our task management system helps you stay organized and ensure that you never miss an important gardening activity. 
            Things change fast in the garden, so if you happen to finish a task later than expected- no worries!  
            Our intelligent scheduling algorithm will adjust any affected dates so that you're back on track again.
        </>
    },
    {
        I: 'F9',
        Q: <>What sets <span className="plannt">plannt.ca</span> apart from other garden planning tools?</>,
        A: <>
            First of all, <span className="plannt">plannt.ca</span> is proudly Canadian (as the .ca domain name suggests).  
            <br/><br/>As seasoned Canadian backyard food gardeners (aka urban farmers), we feel the pain of trying to grow a wide variety of food, in a small space, within the bounds of a limited growing season. 
            We are the #1 customers of our own product and have a personal interest in making sure that it's the best.
            <br/><br/>Our goal is to keep <span className="plannt">plannt.ca</span> clean and simple.  We don't want to be an overwhelming, Swiss Army Knife-esque monstrosity of a planning tool.  
            It's super easy to get started with the platform and (if we do say so ourselves)- fun to use.
            <br/><br/>Drawing on our expertise in data science and process improvement, we built an Analytics dashboard into the platform to allow gardeners to get a live snapshot of how successful their garden is.  What can we say?  We love learning from data.
            <br/><br/>Our personalized recommendations, helpful tips, and easy-to-use interface make <span className="plannt">plannt.ca</span> the ultimate tool for square foot gardeners at all experience levels.  
            <br/><br/>If you're interested to get the inside scoop on new and upcoming <span className="plannt">plannt.ca</span> features, check out our <a href="https://plannt.medium.com" target="_blank" rel="noreferrer noopener">Blog</a>.
        </>
    },
    {
        I: 'F10',
        Q: <>I know someone who would be interested in using <span className="plannt">plannt.ca</span>.  What should I do?</>,
        A: <>
            Our answer will always be: "please tell them about us!"
            However, if you also happen to be a <span className="plannt">plannt.ca</span> member, we've got great news for you!
            <br/><br/><span className="plannt">plannt.ca</span> has a referral program that rewards you for growing our community. 
            Once you become a subscriber, you'll be able to access a referral portal on your Account page.
            This allows you to send invitations to friends with your unique referral link. 
            <br/><br/>When someone signs up for <span className="plannt">plannt.ca</span> using your link and becomes a paid subscriber, you'll automatically receive a 10% discount off the cost of your annual subscription renewal. 
            It's our way of saying thank you for spreading the word.  Your friend will receive a 10% discount too!
            <br/><br/>Oh- and did we mention? You can do this with up to 5 friends for a total of 50% off your renewal cost!  Go social butterfly, go!
        </>
    },
    {
        I: 'F11',
        Q: <>Is my data secure with <span className="plannt">plannt.ca</span>?</>,
        A: <>
            Absolutely! We take data security and privacy seriously. <br/><br/><span className="plannt">plannt.ca</span> implements industry-standard security measures to protect your information. 
            We do not share your personal data with third parties without your consent. 
            For more details, please refer to our <a href={ROUTES.privacyPolicyRoute()}>Privacy Policy</a>.
        </>
    },
    {
        I: 'F12',
        Q: <>What if I need assistance or have additional questions?</>,
        A: <>
            We're here to help!
            <br/><br/>If you have any questions, concerns, or need support, please <a href={ROUTES.contactUsRoute()}>contact us</a> and we'll be happy to provide assistance.
        </>
    }
];

export default faqList;